import { Component, OnInit, ViewChild } from "@angular/core";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {

  year
  money;
  months = 0;
  debt = 0;
  payments = 0;
  isDisabled = true;
  isActive = false;
  isCalc = true;
  iva = 0.1;

  constructor(public storage: Storage) {}

  ngOnInit() {
    let date = new Date()
    this.year = date.getFullYear()
  }

  calculate() {
    if (this.money > 0 && this.months > 0) {
      var loan = {
        money: this.money,
        months: this.months,
      };
      this.storage.set("loan", loan);
      this.isDisabled = false;
      console.log("modified");
    } else {
      this.isDisabled = true;
    }
  }

  changeTab(tab) {
    this.months = tab;
    this.calculate();
  }

  go() {
    this.toggleActive();
    this.money = parseInt(this.money)
    setTimeout(() => {
      this.isCalc = !this.isCalc;
      this.toggleActive();
    }, 300);
    if (this.money < 10999) {
      this.iva = 0.15;
    }
    this.payments = this.months * 2;
    this.debt = Math.ceil((this.money * this.iva * this.months + this.money) / this.payments);
    console.log(this.money,this.payments, this.debt )
  }
  toggleActive() {
    this.isActive = !this.isActive;
  }
  openMail() {
    let formattedBody = `¡Hola!
Necesito un préstamo con los siguientes datos:

- Cantidad: ${this.money}
- Meses: ${this.months}
- Pagos Quincenales: ${this.debt}

Muchas Gracias por su atención
-Enviado desde Gily Capital Web`;    
    window.open(
      "mailto:gilycapital@gmail.com?Subject=Prestamo%20GilyApp&body=" +
        encodeURIComponent(formattedBody),
      "_system"
    );
  }

  openWhats() {
    let formattedBody = `¡Hola!
Necesito un préstamo con los siguientes datos:

*Cantidad:* ${this.money}
*Meses:* ${this.months}
*Pagos Quincenales:* ${this.debt}

Muchas Gracias por su atención
_-Enviado desde Gily Capital Web_`;    
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        formattedBody
      )}&phone=5216622213841`,
      "_system"
    );
  }

  scrollToThis(target) {
    let home = document.getElementById("landing");
    let element = document.getElementById(target);
    home.scrollTo({
      top: element.offsetTop-70,
      left: 0,
      behavior: 'smooth'
    });

  }
}
