import { Component, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: "Conócenos",
      url: "/conocenos",
      simpleName: "conocenos",
    },
    {
      title: "Servicios",
      url: "/experiencia",
      simpleName: "experiencia",
    },
    {
      title: "Simula tu préstamo",
      url: "/prestamo",
      simpleName: "prestamo",
    },
    {
      title: "Solicita tu préstamo",
      url: "/solicita",
      simpleName: "solicita",
    },
    {
      title: "Requisitos",
      url: "/requisitos",
      simpleName: "requisitos",
    },
    {
      title: "Contáctanos",
      url: "/contactanos",
      simpleName: "contactanos",
    },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split("/")[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(
        (page) => page.simpleName.toLowerCase() === path.toLowerCase()
      );
      if(path==''){
        this.selectedIndex=0
      }
    }
  }
}
