import { Component, OnInit } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ActionSheetController } from "@ionic/angular";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-solicita",
  templateUrl: "./solicita.page.html",
  styleUrls: ["./solicita.page.scss"],
})
export class SolicitaPage implements OnInit {
  hasLoan = false;
  money = "";
  months = "";

  debt;
  payments;

  constructor(
    private storage: Storage,
    private modalController: ModalController,
    public actionSheetController: ActionSheetController
  ) {}

  ngOnInit() {
    var iva = 0.1;
    this.storage.get("loan").then((loan) => {
      console.log(loan);
      if (loan) {
        this.hasLoan = true;
        this.money = loan.money;
        this.months = loan.months;
        this.payments = parseInt(this.months) * 2;
        if (parseInt(this.money) < 10999) {
          iva = 0.15;
        }
        this.debt = Math.ceil(
          (parseInt(this.money) * iva * parseInt(this.months) +
            parseInt(this.money)) /
            this.payments
        );
      } else if (this.money != "" && this.money != "") {
        this.hasLoan = true;
        this.payments = parseInt(this.months) * 2;
        if (parseInt(this.money) < 10999) {
          iva = 0.15;
        }
        this.debt = Math.ceil(
          (parseInt(this.money) * iva * parseInt(this.months) +
            parseInt(this.money)) /
            this.payments
        );
      }
    });
  }

  dismiss(){
    this.modalController.dismiss();

  }

  async presentActionSheet() {
    var formattedBody = `¡Hola!
Necesito un préstamo con los siguientes datos:

*Cantidad:* ${this.money}
*Meses:* ${this.months}
*Pagos Quincenales:* ${this.debt}

Muchas Gracias por su atención
_-Enviado desde Gily Capital App_`;
    const actionSheet = await this.actionSheetController.create({
      header: "¿Comó deseas contactarnos?",
      buttons: [
        {
          text: "Correo",
          handler: () => {
            console.log("Play clicked");
            window.open(
              "mailto:gilycapital@gmail.com?Subject=Prestamo%20GilyApp&body=" +
                encodeURIComponent(formattedBody),
              "_system"
            );
          },
        },
        {
          text: "Whatsapp",
          handler: () => {
            console.log("Play clicked");
            window.open(
              `https://api.whatsapp.com/send?text=${encodeURIComponent(
                formattedBody
              )}&phone=5216622213841`,
              "_system"
            );
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ],
    });
    await actionSheet.present();
  }
}
