import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'conocenos',
    pathMatch: 'full'
  },
  {
    path: 'conocenos',
    loadChildren: () => import('./conocenos/conocenos.module').then( m => m.ConocenosPageModule)
  },
  {
    path: 'experiencia',
    loadChildren: () => import('./experiencia/experiencia.module').then( m => m.ExperienciaPageModule)
  },
  {
    path: 'prestamo',
    loadChildren: () => import('./prestamo/prestamo.module').then( m => m.PrestamoPageModule)
  },
  {
    path: 'contactanos',
    loadChildren: () => import('./contactanos/contactanos.module').then( m => m.ContactanosPageModule)
  },
  {
    path: 'solicita',
    loadChildren: () => import('./solicita/solicita.module').then( m => m.SolicitaPageModule)
  },
  {
    path: 'requisitos',
    loadChildren: () => import('./requisitos/requisitos.module').then( m => m.RequisitosPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
